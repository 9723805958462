@media only screen and (max-width: 600px) {
    @import "smallscreen.scss";
}

@media only screen and (min-width: 600px) {
    @import "widescreen.scss";
}

.cv_link
{
    color: white;
    font-size: 15px;
}

.project {
    
    margin:3px;
    min-height:200px;
    padding:5px;
    overflow: hidden;
    position: relative;
    background-color: white;
    border-bottom: 3px solid lightgray;

    img {
        max-width:300px;
        
    }

    .details {
        max-width: 800px;
        .name {
            width:100px;
            padding-right:10px;
            
        }

        opacity: .8;
        
    }

}

.one {

    background-color: white;

    img {
        float:right;
        width:30%;
    }

    .details {
        background-color: white;
        float:left;
        width:60%;
        padding-right:10%;
        font-family: serif, sans-serif;
        font-size: 20px;
        text-align: justify;
    }
}

.two {

    vertical-align: middle;

    img{
        float:left;
        width:30%;
        vertical-align: middle;
    }

    .details {
        background-color: white;
        float:right;
        width:60%;
        padding-right:10%;
        font-family: serif, sans-serif;
        font-size: 20px;
        text-align: justify;
    }

}


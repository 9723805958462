@media only screen and (max-width: 600px) {
  * {
    box-sizing: border-box;
  }
  .text-center {
    text-align: center !important;
  }
  .App {
    flex: 1;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .main {
    flex: 1;
    height: 100vh;
    background-color: #5FCB71;
  }
  .main .left {
    flex: 1;
    resize: horizontal;
    overflow: auto;
    min-width: 400px;
    min-height: 300px;
    background-image: url("../../public/img/me.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .main .left h1 {
    display: none;
  }
  .main .left .intro {
    position: absolute;
    right: 0px;
    max-width: 300px;
    color: white;
    vertical-align: middle;
    height: 300px;
    background-color: #000;
    color: #fff;
    opacity: 0.5;
    padding: 6px;
    text-align: justify;
  }
  .main .left .pic {
    display: none;
  }
  .main .right {
    width: 100vh;
    background-color: white;
    min-height: 500px;
  }
  .main .right .top {
    background-color: lightgrey;
    min-height: 50px;
    margin: 0px;
  }
  .main .right .top h1 {
    margin: 0px;
  }
}
@media only screen and (min-width: 600px) {
  * {
    box-sizing: border-box;
  }
  .text-center {
    text-align: center !important;
  }
  .App {
    flex: 1;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .main {
    flex: 1;
    height: 100vh;
  }
  .main .left {
    flex: 1;
    resize: horizontal;
    overflow: auto;
    min-width: 200px;
    background-color: black;
  }
  .main .left h1 {
    color: white;
    text-shadow: 2px 2px black;
    text-align: center;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .main .left img {
    max-height: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .main .left .intro {
    margin: 8px;
    color: white;
  }
  .main .left .pic {
    background-image: url("../../public/img/david.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 250px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .main .right {
    flex: 3;
    background-color: white;
    min-height: 500px;
  }
  .main .right .top {
    background-color: lightgray;
    min-height: 50px;
  }
  .main .right .top h1 {
    color: black;
    margin: 0px;
  }
  .main .right .top h2 {
    color: black;
  }
}
.cv_link {
  color: white;
  font-size: 15px;
}

.project {
  margin: 3px;
  min-height: 200px;
  padding: 5px;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-bottom: 3px solid lightgray;
}
.project img {
  max-width: 300px;
}
.project .details {
  max-width: 800px;
  opacity: 0.8;
}
.project .details .name {
  width: 100px;
  padding-right: 10px;
}

.one {
  background-color: white;
}
.one img {
  float: right;
  width: 30%;
}
.one .details {
  background-color: white;
  float: left;
  width: 60%;
  padding-right: 10%;
  font-family: serif, sans-serif;
  font-size: 20px;
  text-align: justify;
}

.two {
  vertical-align: middle;
}
.two img {
  float: left;
  width: 30%;
  vertical-align: middle;
}
.two .details {
  background-color: white;
  float: right;
  width: 60%;
  padding-right: 10%;
  font-family: serif, sans-serif;
  font-size: 20px;
  text-align: justify;
}/*# sourceMappingURL=style.css.map */
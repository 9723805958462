* {
  box-sizing: border-box;
}

.text-center {
  text-align: center !important;
}


.App {
  flex:1;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.main {

  flex: 1;
  
  height: 100vh;
  background-color:#5FCB71;

  .column {
      //float: left;    
      //display: inline-block;
    }

  .left {
    
    flex:1;
    resize: horizontal;
    overflow: auto;
    min-width: 400px;
    min-height:300px;
    background-image: url('../../public/img/me.png');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    h1 {
      display:none;
    }



    .intro {

      position:absolute;
      right:0px;
      max-width: 300px;
      color:white;
      vertical-align:middle;
      height:300px;
      background-color: #000;
      color: #fff;
      opacity: .5;
      padding:6px;
      text-align: justify;
    }

    .pic {

      display: none;


    }
  }

  .right {
      //width: 80%;

      width:100vh;
      background-color: white;
      min-height:500px;

      .top {
        background-color: lightgrey;
        min-height:50px;
        margin:0px;


        h1 {
          margin:0px;
        }
      }
  }


}


* {
  box-sizing: border-box;
}

.text-center {
  text-align: center !important;
}


.App {
  flex:1;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.main {

  flex: 1;
  
  height: 100vh;

  .column {
      //float: left;    
      //display: inline-block;
    }

  .left {
    flex:1;
    resize: horizontal;
    overflow: auto;
    min-width: 200px;
    background-color:black;

    h1 {
      color: white;
      text-shadow: 2px 2px black;
      text-align: center;
      width:200px;
      margin-left:auto;
      margin-right:auto;
    }

    img {
      max-height: 200px;
      margin-left:auto;
      margin-right:auto;
      display:block;
    }


    .intro {

      margin:8px;
      color: white;

    }

    .pic {

      background-image: url('../../public/img/david.jpg');
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      height:250px;
      width:250px;
      margin-left:auto;
      margin-right:auto;
    }
  }

  .right {
      //width: 80%;
      flex: 3;

      background-color: white;
      min-height:500px;

      .top {
        background-color: lightgray;
        min-height:50px;

        h1 {
          color: black;
          margin:0px;
        }

        h2 {
          color: black;
          
        }
      }
  }


}